<template>
  <v-form ref="cpInquiry" v-on:submit.prevent class="inquiry--form">
    <v-layout>
      <v-flex>
        <v-card outlined>
          <v-card-text class="px-4 pb-0">
            <div class="title font-weight-medium pb-2">Contact Details</div>
            <div>
              Please provide contact details.
              {{ company ? company.name : "We" }} may contact you to help with
              your inquiry.
            </div>
          </v-card-text>
          <v-card-text class="pa-3">
            <v-layout row wrap>
              <!-- Name -->
              <v-flex class="pt-2" :class="{ sm6: !isExtraSmall }">
                <v-text-field
                  label="Name"
                  hide-details
                  clearable
                  :max-height="30"
                  prepend-icon="mdi-account"
                  v-model="params.fullName"
                  :rules="[(v) => !!v || 'required']"
                ></v-text-field>
              </v-flex>
              <!-- Email -->
              <v-flex class="pt-2" :class="{ sm6: !isExtraSmall }">
                <v-text-field
                  label="Email"
                  type="email"
                  hide-details
                  v-model="params.emails"
                  clearable
                  prepend-icon="mdi-at"
                  :rules="emailValidationRules"
                ></v-text-field>
              </v-flex>
              <!-- Number of Passengers -->
              <v-flex :class="{ sm6: !isExtraSmall }" class="pt-2">
                <v-text-field
                  label="Passengers"
                  type="number"
                  v-model="params.pax"
                  hide-details
                  prepend-icon="mdi-account-group"
                  clearable
                ></v-text-field>
              </v-flex>
              <!-- Phone -->
              <v-flex
                class="pt-2 custom--tel-input"
                :class="{ sm6: !isExtraSmall }"
              >
                <vue-tel-input
                  v-bind:dark="$vuetify.theme.dark"
                  :preferredCountries="['US']"
                  v-model="params.phoneNumbers"
                  placeholder="Phone"
                  enabledCountryCode
                  :class="isValidPhoneNumber ? '' : 'has-error'"
                  validCharactersOnly
                  autocomplete="off"
                  @validate="validatePhoneNumber($event, false)"
                  @input="
                    isValidPhoneNumber =
                      params.phoneNumbers.length == 0 || false
                  "
                />
              </v-flex>
              <!-- Remarks -->
              <v-flex sm12 class="pt-2">
                <v-textarea
                  auto-grow
                  label="Remarks"
                  hide-details
                  :rows="3"
                  clearable
                  v-model="params.remarks"
                  prepend-icon="mdi-text"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row class="pl-1">
              <v-checkbox
                label="Send me matching trip alerts on this route"
                hide-details
                v-model="params.sendTripAlert"
              ></v-checkbox>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-text v-if="showMessage">
            <div v-if="successMessage" class="success--text">
              <v-icon color="success">mdi-check</v-icon>
              {{ successMessage }}
            </div>
            <div v-if="errorMessage" class="error--text">
              <v-icon color="error">mdi-close</v-icon>
              {{ errorMessage }}
            </div>
          </v-card-text>
          <v-card-actions>
            <powered-by :isExtraSmall="isExtraSmall" />
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="$emit('close')">Cancel</v-btn>
            <v-btn
              depressed
              color="primary"
              @click="sendInquiry"
              :loading="loading"
            >
              <v-icon left>mdi-send</v-icon>Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { VueTelInput } from "vue-tel-input";
export default {
  props: {
    trip: Object,
    isExtraSmall: Boolean,
    searchParams: Object,
    company: Object,
    config: Object,
  },
  components: {
    VueTelInput,
  },
  data: () => ({
    loading: false,
    successMessage: "",
    errorMessage: "",
    showMessage: false,
    isValidPhoneNumber: true,
    params: {
      tripId: null,
      tripCategoryId: null,
      communicationType: 1,
      phoneNumbers: "",
      emails: null,
      departureAirportId: null,
      departureAirportRadius: 0,
      etd: null,
      arrivalAirportId: null,
      arrivalAirportRadius: 0,
      eta: null,
      fullName: null,
      pax: null,
      remarks: null,
      title: null,
      flexibleDays: null,
      sendTripAlert: false,
    },
    emailValidationRules: [
      (v) => !!v || "Required",
      (format) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$|^$/i.test(format) ||
        "Invalid email",
    ],
  }),
  created() {
    if (this.trip) {
      this.params.tripId = this.trip.tripId || null;
    }

    // Dates
    if (this.searchParams.fromDate) {
      this.params.etd = this.searchParams.fromDate;
    } else if (this.trip) {
      this.params.etd = this.trip.from;
    } else {
      this.params.etd = null;
    }

    if (this.searchParams.toDate) {
      this.params.eta = this.searchParams.toDate;
    } else if (this.trip) {
      this.params.eta = this.trip.to;
    } else {
      this.params.eta = null;
    }

    // Airports
    if (
      this.searchParams.departureAirport &&
      this.searchParams.departureAirport.id
    ) {
      this.params.departureAirportId = this.searchParams.departureAirport.id;
    } else if (this.trip) {
      if (this.trip.departure) {
        this.params.departureAirportId = this.trip.departure.id;
      } else {
        this.params.departureAirportId = null;
      }
    } else {
      this.params.departureAirportId = null;
    }

    if (
      this.searchParams.arrivalAirport &&
      this.searchParams.arrivalAirport.id
    ) {
      this.params.arrivalAirportId = this.searchParams.arrivalAirport.id;
    } else if (this.trip) {
      if (this.trip.arrival) {
        this.params.arrivalAirportId = this.trip.arrival.id;
      } else {
        this.params.arrivalAirportId = null;
      }
    } else {
      this.params.arrivalAirportId = null;
    }

    //Pax
    if (this.trip) {
      this.params.pax = this.trip.pax;
    } else {
      this.params.pax = null;
    }

    // flexible days
    if (this.searchParams.flexibleDays) {
      this.params.flexibleDays = this.searchParams.flexibleDays;
    } else if (this.trip) {
      this.params.flexibleDays = this.trip.flexibleDays;
    }

    // Radius
    this.params.departureAirportRadius =
      this.searchParams.departureAirportRadius;
    this.params.arrivalAirportRadius = this.searchParams.arrivalAirportRadius;

    this.params.tripCategoryId =
      this.searchParams.tripCategoryId > 0
        ? this.searchParams.tripCategoryId
        : null;
  },
  watch: {
    showMessage: {
      immediate: true,
      handler() {
        if (this.showMessage == true) {
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
            this.successMessage = "";
          }, 3000);
        }
      },
    },
  },
  methods: {
    validatePhoneNumber(number) {
      if (number.number.e164) {
        this.params.phoneNumbers = number.number.significant;
        this.params.phoneNumbersE164 = number.number.e164;
        this.isValidPhoneNumber = number.isValid;
      }
    },
    titleFormation(params) {
      let title = [];
      // Trip Category

      params.tripCategoryId
        ? title.push(
            params.tripCategoryId == 2544
              ? "Empty Leg"
              : params.tripCategoryId == 2542
              ? "One Way"
              : params.tripCategoryId == 2538
              ? "Round Trip"
              : ""
          )
        : null;
      // Sectors
      let departureAirport = null;
      if (params.departureAirportId) {
        if (this.trip) {
          departureAirport = this.trip.departure
            ? this.trip.departure.icao
            : "";
        } else {
          departureAirport = this.searchParams.departureAirport
            ? this.searchParams.departureAirport.icao
            : "";
        }
      }
      let arrivalAirport = null;
      if (params.arrivalAirportId) {
        if (this.trip) {
          arrivalAirport = this.trip.arrival ? this.trip.arrival.icao : "";
        } else {
          arrivalAirport = this.searchParams.arrivalAirport
            ? this.searchParams.arrivalAirport.icao
            : "";
        }
      }
      title.push(
        _.join(_.filter([departureAirport, arrivalAirport], _.identity), "-")
      );
      // Dates
      let etd = params.etd ? this.$moment(params.etd).format("MM/DD") : null;
      let eta = params.eta ? this.$moment(params.eta).format("MM/DD") : null;
      title.push(_.join(_.filter([etd, eta], _.identity), "-"));

      // push flexible title
      if (params.flexibleDays) {
        title.push(
          `(Flexible ${params.flexibleDays} ${
            params.flexibleDays == 1 ? "day" : "days"
          })`
        );
      }

      // PAX
      if (params.pax) title.push(`PAX ${params.pax}`);

      return _.trim(_.join(title, " "));
    },
    sendInquiry() {
      if (this.$refs.cpInquiry.validate()) {
        let params = _.cloneDeep(this.params);
        if (
          params.phoneNumbers.length > 0 &&
          this.isValidPhoneNumber &&
          params.phoneNumbersE164
        ) {
          params.phoneNumbers = params.phoneNumbersE164;
          delete params.phoneNumbersE164;
        }
        if (!this.isValidPhoneNumber && this.params.phoneNumbers > 0) {
          return;
        }

        this.loading = true;
        params.title = this.titleFormation(params);

        let config = {
          headers: {
            "app-code": this.config.appCode,
            "app-widget": this.config.appWidget,
          },
        };
        this.axios
          .post("/widget/trip/inquiry/post", params, config)
          .then((response) => {
            this.loading = false;
            if (response.data.result) {
              this.$refs.cpInquiry.reset();
              this.params.phoneNumbers = "";
              this.showMessage = true;
              this.successMessage = response.data.message;
            }
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
              this.showMessage = true;
              this.errorMessage = "An unexpected error has been occurred.";
            }
          });
      }
    },
  },
};
</script>
