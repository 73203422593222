<template>
  <v-layout class="pa-10">
    <v-overlay :value="true" absolute opacity="0.1">
      <v-chip color="grey">
        <v-icon left>mdi-alert-circle-outline</v-icon>
        {{ message }}
      </v-chip>
    </v-overlay>
  </v-layout>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: "There is an issue with the specified widget.",
    },
  },
};
</script>