import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import axios from "@/plugins/axios";
// eslint-disable-next-line no-unused-vars
import index from "@/plugins/index";

import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import "@/filters/dateFilters";
import VueLodash from "vue-lodash";


Vue.prototype.$baseUrl = process.env.NODE_ENV == "production" ? process.env.VUE_APP_CDN_URL : ""

import { join, filter, map, identity, size, split, cloneDeep, reverse, isUndefined, trim, debounce, includes } from 'lodash';

import { parsePhoneNumberFromString } from "libphonenumber-js";

Vue.use(VueLodash, { lodash: { join, filter, map, identity, size, split, cloneDeep, reverse, isUndefined, trim, debounce, includes } });

import vueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";

import "@/style.scss";

import loading from "@/components/loading";
import poweredBy from "@/components/poweredBy";

Vue.config.productionTip = process.env.NODE_ENV == 'production';

Vue.use(vueCustomElement);
Vue.component("loading", loading);
Vue.component("poweredBy", poweredBy);

Vue.customElement("charterpad-widget", App, {
  // Root component's definition is passed to this hook just before Vue instance creation - so you can modify it
  beforeCreateVueInstance(RootComponentDefinition) {
    RootComponentDefinition.vuetify = vuetify;
    return RootComponentDefinition;
  },
});

Vue.filter("globalPhoneNumberFormatter", (phone) => {
  let formatted = phone
    ? _.join(
      _.filter(
        [
          parsePhoneNumberFromString(phone) ? `+${parsePhoneNumberFromString(phone).countryCallingCode}` : "",
          parsePhoneNumberFromString(phone) ? parsePhoneNumberFromString(phone).formatNational() : _.trim(phone)
        ],
        _.identity
      ),
      " "
    )
    : phone;
  return formatted;
});
