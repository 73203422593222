<template>
  <v-autocomplete
    v-bind:attach="`#${attach}`"
    v-model="val"
    item-text="fullName"
    item-value="id"
    class="selectFields-input"
    name="airportSearch"
    hide-no-data
    :class="classNames"
    :items="items"
    :loading="loading"
    :rules="rules"
    v-bind="$attrs"
    :search-input.sync="searchInput"
    @click:clear="$emit('onClear')"
  >
    <template v-slot:item="data">
      <v-layout column class="py-2">
        <div class="caption primary--text font-weight-medium">
          {{ data.item.icao ? data.item.icao : "" }}
          {{
            data.item.icao && (data.item.iata || data.item.faaCode) ? " / " : ""
          }}
          {{
            data.item.iata
              ? data.item.iata
              : data.item.faaCode
              ? data.item.faaCode
              : ""
          }}
        </div>
        <div class="caption grey--text">
          {{
            _.join(
              _.filter(
                [
                  data.item.name,
                  data.item.city ? data.item.city.name : "",
                  data.item.state ? data.item.state.name : "",
                  data.item.country ? data.item.country.name : "",
                ],
                _.identity
              ),
              ", "
            )
          }}
        </div>
      </v-layout>
    </template>
    <template v-slot:selection="{ item }" v-if="forFilter">
      <span v-if="!multiple">
        {{
          item.icao
            ? item.icao
            : item.iata
            ? item.iata
            : item.faaCode
            ? item.faaCode
            : ""
        }}
      </span>
    </template>
    <template v-slot:selection="{ item }" v-else>
      <div v-if="!multiple">
        {{ item.icao || item.iata || item.faaCode }}
      </div>
      <v-chip v-else small>
        {{ item.icao || item.iata || item.faaCode }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    classNames: {
      type: String,
      default: null,
    },
    forFilter: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
    attach: String,
    value: {
      type: [Number, String, Object, Array],
      default: function () {
        return {} || [];
      },
    },
  },
  data: () => ({
    items: [],
    loading: false,
    searchInput: "",
  }),
  mounted() {
    this.getItems = _.debounce(function (val) {
      if (val && val.length >= 3) {
        this.loading = true;
        this.axios
          .get(`/api/airport/${val}/Keyword`)
          .then((response) => {
            if (response && response.data.success) {
              this.items = [...response.data.result];
              if (this.val) this.items = [...this.items, ...this.val];
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }, 1000);
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(v) {
        this.searchInput = "";
        this.$emit("onSelect", v);
      },
    },
  },
  watch: {
    searchInput(val) {
      this.getItems(val);
    },
  },
};
</script>