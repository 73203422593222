<template>
  <v-container
    fluid
    class="pa-0 py-2 cp-availability-widget"
    grid-list-md
    ref="mainContainer"
  >
    <v-layout row>
      <v-flex sm12>
        <v-form ref="cpFilters" v-model="mainForm" v-on:submit.prevent>
          <v-layout row wrap class="mt-3">
            <!-- Type -->
            <v-flex
              :class="gridClassName"
              class="mb-1"
              :id="`choose-type-${_uid}`"
            >
              <v-select
                v-model="category"
                :items="tabs"
                label="Type"
                outlined
                hide-details
                item-text="name"
                item-value="index"
                placeholder="Choose Type"
                :menu-props="{ offsetY: true, nudgeBottom: 27 }"
                close-on-content-click
                :attach="`#choose-type-${_uid}`"
              ></v-select>
            </v-flex>
            <!-- Date Selection -->
            <v-flex
              :class="gridClassName"
              :id="`date-options-${_uid}`"
              class="mb-1"
            >
              <v-menu
                fixed
                :attach="`#date-options-${_uid}`"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="transformedDates"
                    outlined
                    :label="
                      category == 0 || category == 1
                        ? 'Departure Date'
                        : 'Departure/Return  Date'
                    "
                    placeholder="Choose dates"
                    readonly
                    v-on="on"
                    hide-details
                    clearable
                    @click:clear="dates = []"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  :min="minDate"
                  range
                  scrollable
                  color="primary"
                  @input="
                    dates = $event;
                    dates.length == 2 ? (menu = false) : '';
                  "
                  v-if="category == 2 && menu"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates"
                  no-title
                  :min="minDate"
                  range
                  scrollable
                  color="primary"
                  @input="
                    dates = $event;
                    dates.length == 2
                      ? (dates = [dates[1]])((menu = false))
                      : '';
                    dates.length == 1 ? (menu = false) : '';
                  "
                  v-if="category == 0 || (category == 1 && menu)"
                ></v-date-picker>
              </v-menu>
              <template v-if="_.size(dates) == 0 ? false : true">
                <v-menu
                  fixed
                  :attach="`#date-options-${_uid}`"
                  v-model="optionalDaysList"
                  offset-y
                  close-on-content-click
                  class="mt-1"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :height="20"
                      text
                      color="primary"
                      dark
                      v-on="on"
                      class="mt-1"
                    >
                      +/- {{ params.optionalDays ? params.optionalDays : "" }}
                      {{ params.optionalDays == 1 ? "day" : "days" }}
                      <v-icon right x-small>mdi-details</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="params.optionalDays = null"
                      v-if="params.optionalDays"
                    >
                      <v-list-item-title>Clear</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-for="(item, index) in 5"
                      :key="index"
                      @click="params.optionalDays = item"
                    >
                      <v-list-item-title>
                        +/- {{ item }}
                        {{ item == 1 ? "day" : "days" }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-flex>
            <!-- From Airport -->
            <v-flex :class="gridClassName">
              <airport-search
                :id="`from-airport-${_uid}`"
                :attach="`from-airport-${_uid}`"
                v-model="params.departureAirport"
                label="From"
                placeholder="ICAO/IATA"
                hideDetails
                returnObject
                clearable
                @onSelect="params.departureAirport = $event"
                outlined
                required
                :rules="[(v) => !!v || 'required']"
                @onClear="params.departureAirport = null"
              />
              <v-slider
                v-model="params.departureAirportRadius"
                :step="50"
                :max="1000"
                hide-details
                :disabled="params.departureAirport ? false : true"
              >
                <template slot="label">
                  <span class="caption">
                    Radius {{ params.departureAirportRadius }} NM
                  </span>
                </template>
              </v-slider>
            </v-flex>
            <!-- To Airport -->
            <v-flex :class="gridClassName">
              <airport-search
                :id="`to-airport-${_uid}`"
                :attach="`to-airport-${_uid}`"
                v-model="params.arrivalAirport"
                label="To"
                placeholder="ICAO/IATA"
                hideDetails
                returnObject
                clearable
                @onSelect="params.arrivalAirport = $event"
                outlined
                required
                :rules="[(v) => !!v || 'required']"
                @onClear="params.arrivalAirport = null"
              />
              <v-slider
                v-model="params.arrivalAirportRadius"
                :step="50"
                :max="1000"
                hide-details
                :disabled="params.arrivalAirport ? false : true"
              >
                <template slot="label">
                  <span class="caption">
                    Radius {{ params.arrivalAirportRadius }} NM
                  </span>
                </template>
              </v-slider>
            </v-flex>
          </v-layout>
          <v-flex sm12>
            <v-layout row wrap class="ma-0">
              <v-flex class="mb-3 mx-auto px-0">
                <quote-inquire
                  :isExtraSmall="isExtraSmall"
                  :cpForm="$refs.cpFilters"
                  :mainForm="mainForm"
                  :searchParams="params"
                  :company="company"
                  :config="config"
                ></quote-inquire>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import airportSearch from "@/components/airportSearch";
import quoteInquire from "@/components/quote-inquire";
export default {
  props: {
    company: Object,
    config: Object,
    isSmall: String,
    isExtraSmall: Boolean,
    isMediumScreen: Boolean,
  },
  components: { airportSearch, quoteInquire },
  data: () => ({
    dialog: false,
    mainForm: false,
    optionalDaysList: false,
    trips: [],
    category: 0,
    errorMessage: null,
    loading: false,
    isLoadMore: false,
    dates: [],
    menu: false,
    tabs: [
      {
        index: 0,
        id: 2544,
        name: "Empty Leg Trips",
        icon: "mdi-thumb-up-outline",
      },
      {
        index: 1,
        id: 2542,
        name: "One-way",
        icon: "mdi-ray-start-arrow",
      },
      {
        index: 2,
        id: 2538,
        name: "Round Trip",
        icon: "mdi-repeat",
      },
    ],
    params: {
      tripCategoryId: 0,
      departureAirportId: null,
      departureAirport: null,
      departureAirportRadius: 0,
      arrivalAirportId: null,
      arrivalAirport: null,
      arrivalAirportRadius: 0,
      optionalDays: null,
      fromDate: null,
      toDate: null,
      index: 0,
      size: 5,
    },
    minDate: null,
    noRecordFound: null,
  }),
  created() {
    this.minDate = this.$moment().format("YYYY-MM-DD");
  },
  computed: {
    gridClassName() {
      return this.isSmall == "sm4" ? "sm6" : this.isSmall;
    },
    cpvalidate() {
      return this.$refs.cpFilters && this.$refs.cpFilters.validate();
    },
    transformedDates: {
      set() {},
      get() {
        return this.$options.filters.dateRange(this.dates[0], this.dates[1]);
      },
    },
    selectedTab() {
      return this.tabs[this.category];
    },
  },
  methods: {
    toggleModal(trip) {
      this.$set(trip, "toggleInquiry", true);
    },
  },
  watch: {
    menu: {
      immediate: false,
      handler() {
        if (this.menu == false) this.$refs.menu.save(this.dates);
      },
    },
    category: {
      immediate: true,
      handler() {
        this.dates = [];
        this.params.tripCategoryId = this.tabs[this.category].id;
      },
    },
    "params.departureAirportId"() {
      if (!this.params.departureAirportId)
        this.params.departureAirportRadius = null;
      if (_.isUndefined(this.params.departureAirportId))
        this.params.departureAirportId = null;
    },
    "params.arrivalAirportId"() {
      if (!this.params.arrivalAirportId)
        this.params.arrivalAirportRadius = null;

      if (_.isUndefined(this.params.arrivalAirportId))
        this.params.arrivalAirportId = null;
    },
    dates() {
      if (_.size(this.dates)) {
        if (_.size(this.dates) == 2) {
          if (this.$moment(this.dates[0]).isAfter(this.dates[1])) {
            this.dates = _.reverse(this.dates);
          }
        }
        this.params.fromDate = this.dates[0];
        this.params.toDate = this.dates[1] ? this.dates[1] : null;
      } else {
        this.params.fromDate = null;
        this.params.toDate = null;
        this.params.optionalDays = null;
      }
    },
  },
};
</script>
<style lang="scss">
.cp-availability-widget {
  .trip-card {
    overflow: hidden;
  }
  .charterpad-tabs {
    .v-tab {
      text-transform: initial;
    }
  }
  .reset-btn {
    position: absolute !important;
    margin-left: 90px;
    margin-top: 5px;
  }
  .inquire-wrap {
    border: 2px solid #f5f5f5;
  }
  .cp-carousel {
    z-index: 1 !important;
    .v-carousel__controls .v-carousel__controls__item {
      margin: 0px !important;
      .v-icon {
        font-size: 8px !important;
      }
    }
  }
  .skelton-carousel-item {
    position: relative !important;
    z-index: 0;
    .v-skeleton-loader__image {
      height: 100% !important;
    }
  }
  .v-date-picker-table table {
    border: none !important;
    tr td {
      padding: 0px !important;
      border: none !important;
    }
  }
}
</style>
