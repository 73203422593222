<template>
  <v-app :id="`app-${_uid}`">
    <v-container class="pa-0" :ref="`app${_uid}`">
      <v-main>
        <component
          :is="loadComponent"
          v-bind="$props"
          @error="
            ($event) => {
              errorMessage = $event;
            }
          "
          v-bind:message="errorMessage"
          :company="company"
          :config="$props"
          :isSmall="isSmall"
          :isExtraSmall="isExtraSmall"
          :isMediumScreen="isMediumScreen"
          :containerWidth="containerWidth"
        />
      </v-main>
      <v-footer v-if="!hideFooter && company" class="px-1">
        <template v-if="company">
          <span class="font-weight-medium px-2">{{ company.name }}</span>
          <span class="caption px-1">
            <v-icon left small color="primary">mdi-email</v-icon>
            <a :href="`mailto:${_.toLower(company.email)}`">
              {{ _.toLower(company.email) }}
            </a>
          </span>
          <span class="caption px-1">
            <v-icon left small color="primary">mdi-phone</v-icon>
            {{ company.phone1 | globalPhoneNumberFormatter }}
            {{ company.phone2 ? " / " : "" }}
            {{ company.phone2 | globalPhoneNumberFormatter }}
          </span>
        </template>
      </v-footer>
    </v-container>
  </v-app>
</template>
<script>
import availability from "@/components/availability";
import quote from "@/components/quote";
import error from "@/components/error";

export default {
  components: { availability, quote, error },
  props: {
    appWidget: String,
    appCode: String,
    appThemePrimary: String,
    appThemeSecondary: String,
    hideFooter: { type: Boolean, default: false },
    appWidgetTypes: { type: String, default: "empty-leg,one-way,round-trip" },
    appTheme: {
      type: String,
      default: "light",
      validator: function (value) {
        return ["dark", "light"].indexOf(value) !== -1;
      },
    },
  },
  data: () => ({
    widgets: ["availability", "quote"],
    errorMessage: null,
    company: null,
    defultErrorMessage:
      "The specified widget is not valid. Please check the documentation these configurations are case sensitive.",
    isSmall: null,
    isExtraSmall: false,
    isMediumScreen: false,
    containerWidth: null,
  }),
  created() {
    window.getApp = this;
    this.$vuetify.theme.dark = this.appTheme === "dark";
    if (this.appThemePrimary)
      this.$vuetify.theme.themes[this.appTheme].primary = this.appThemePrimary;
    if (this.appThemeSecondary)
      this.$vuetify.theme.themes[this.appTheme].secondary =
        this.appThemeSecondary;
    // Fetch Company Info
    this.fetchCompanyInfo();
  },
  mounted() {
    let selector = this.$refs[`app${this._uid}`];
    this.containerWidth = selector.offsetWidth;
    this.isSmall =
      selector.offsetWidth <= this.$vuetify.breakpoint.thresholds.xs
        ? "sm12"
        : selector.offsetWidth > this.$vuetify.breakpoint.thresholds.xs &&
          selector.offsetWidth <= this.$vuetify.breakpoint.thresholds.sm
        ? "sm4"
        : "sm3";
    // To manage the parent on small width
    this.isExtraSmall =
      selector.offsetWidth <= this.$vuetify.breakpoint.thresholds.xs
        ? true
        : false;
    // To manage the medium schreen for full feature list
    this.isMediumScreen =
      selector.offsetWidth > this.$vuetify.breakpoint.thresholds.sm &&
      selector.offsetWidth < 1000;
  },
  computed: {
    loadComponent() {
      return _.includes(this.widgets, this.appWidget) && !this.errorMessage
        ? this.appWidget
        : "error";
    },
  },
  methods: {
    fetchCompanyInfo() {
      let config = {
        headers: {
          "app-code": this.appCode,
          "app-widget": this.appWidget,
        },
      };
      this.axios
        .get(
          `/widget/trip/company?appCode=${this.appCode}&appWidget=${this.appWidget}`,
          config
        )
        .then((response) => {
          if (response.data.success) {
            this.company = response.data.result;
          } else {
            this.errorMessage =
              response.data.message || this.defultErrorMessage;
          }
        })
        .catch((error) => {
          this.errorMessage =
            error.response.data.message || this.defultErrorMessage;
        });
    },
  },
  watch: {
    loadComponent: {
      immediate: true,
      handler() {
        if (this.loadComponent == "error" && this.errorMessage == null)
          this.errorMessage = this.defultErrorMessage;
      },
    },
  },
};
</script>