<template>
  <v-container
    fluid
    class="pa-0 py-2 cp-availability-widget"
    grid-list-md
    ref="mainContainer"
  >
    <v-layout row>
      <v-flex sm12>
        <v-tabs
          v-model="category"
          class="charterpad-tabs"
          background-color="transparent"
          centered
        >
          <v-tab v-for="tab in tabs" :key="tab.id" ripple>
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
        <v-form ref="cpFilters" v-on:submit.prevent>
          <v-layout row wrap justify-center class="mt-3">
            <v-flex :class="isSmall">
              <airport-search
                :id="`from-airport-${_uid}`"
                :attach="`from-airport-${_uid}`"
                v-model="params.departureAirport"
                label="From"
                placeholder="ICAO/IATA"
                hideDetails
                returnObject
                clearable
                @onSelect="params.departureAirport = $event"
                @onClear="params.departureAirport = null"
                outlined
                :rules="[(v) => !!v || 'required']"
              />
              <v-slider
                v-model="params.departureAirportRadius"
                :step="50"
                :max="1000"
                hide-details
                :disabled="params.departureAirport ? false : true"
              >
                <template slot="label">
                  <span class="caption">
                    Radius {{ params.departureAirportRadius }} NM
                  </span>
                </template>
              </v-slider>
            </v-flex>
            <v-flex :class="isSmall">
              <airport-search
                :id="`to-airport-${_uid}`"
                :attach="`to-airport-${_uid}`"
                v-model="params.arrivalAirport"
                label="To"
                placeholder="ICAO/IATA"
                hideDetails
                returnObject
                clearable
                @onSelect="params.arrivalAirport = $event"
                @onClear="params.arrivalAirport = null"
                outlined
                :rules="[(v) => !!v || 'required']"
              />
              <v-slider
                v-model="params.arrivalAirportRadius"
                :step="50"
                :max="1000"
                hide-details
                :disabled="params.arrivalAirport ? false : true"
              >
                <template slot="label">
                  <span class="caption"
                    >Radius {{ params.arrivalAirportRadius }} NM</span
                  >
                </template>
              </v-slider>
            </v-flex>
            <v-flex :class="isSmall" :id="`date-options-${_uid}`">
              <v-menu
                fixed
                :attach="`#date-options-${_uid}`"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="transformedDates"
                    outlined
                    :label="
                      category == 0 || category == 1
                        ? 'Departure Date'
                        : 'Departure/Return  Date'
                    "
                    placeholder="Choose dates"
                    readonly
                    v-on="on"
                    hide-details
                    clearable
                    @click:clear="dates = []"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  :min="minDate"
                  range
                  scrollable
                  color="primary"
                  @input="
                    dates = $event;
                    dates.length == 2 ? (menu = false) : '';
                  "
                  v-if="category == 2 && menu"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates"
                  no-title
                  :min="minDate"
                  range
                  scrollable
                  color="primary"
                  @input="
                    dates = $event;
                    dates.length == 2
                      ? (dates = [dates[1]])((menu = false))
                      : '';
                    dates.length == 1 ? (menu = false) : '';
                  "
                  v-if="category == 0 || (category == 1 && menu)"
                ></v-date-picker>
              </v-menu>
              <template v-if="_.size(dates) == 0 ? false : true">
                <v-menu
                  fixed
                  :attach="`#date-options-${_uid}`"
                  v-model="flexibleDaysList"
                  offset-y
                  close-on-content-click
                  class="mt-1"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :height="20"
                      text
                      color="primary"
                      dark
                      v-on="on"
                      class="mt-1"
                    >
                      +/- {{ params.flexibleDays ? params.flexibleDays : "" }}
                      {{ params.flexibleDays == 1 ? "day" : "days" }}
                      <v-icon right x-small>mdi-details</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="params.flexibleDays = null"
                      v-if="params.flexibleDays"
                    >
                      <v-list-item-title>Clear</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-for="(item, index) in 5"
                      :key="index"
                      @click="params.flexibleDays = item"
                    >
                      <v-list-item-title>
                        +/- {{ item }}
                        {{ item == 1 ? "day" : "days" }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-flex>
          </v-layout>
          <v-layout row justify-center class="my-2">
            <v-flex shrink>
              <v-layout column>
                <v-btn depressed color="primary" @click="applySearch(true)">
                  <v-icon left>mdi-magnify</v-icon>Search
                </v-btn>
              </v-layout>
            </v-flex>
            <!-- Reset Filters -->
            <v-tooltip right v-if="showReset" fixed>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="secondary" text @click="applySearch()">
                  Reset
                </v-btn>
              </template>
              <span>Reset filters</span>
            </v-tooltip>
          </v-layout>
        </v-form>
      </v-flex>
      <v-flex sm12>
        <v-layout row wrap class="ma-0">
          <template v-if="_.size(trips)">
            <template v-for="(trip, index) in trips">
              <!-- Trip Detail Card -->
              <v-flex sm12 :key="index" class="mb-2">
                <v-card outlined class="trip-card">
                  <v-layout
                    :class="isExtraSmall ? 'column' : 'row'"
                    fill-height
                  >
                    <!-- Images slider -->
                    <v-flex sm3 class="pa-0" v-if="_.size(trip.images)">
                      <v-carousel
                        :show-arrows="false"
                        hide-delimiter-background
                        :height="isExtraSmall ? 250 : 165"
                        class="cp-carousel"
                        delimiter-icon="mdi-circle"
                      >
                        <v-carousel-item
                          v-for="(image, index) in trip.images"
                          :key="index"
                        >
                          <v-img
                            :src="`${axios.defaults.baseURL}api/image/aircraft/${image}?Format=Compressed`"
                            :height="isExtraSmall ? 250 : 165"
                          />
                        </v-carousel-item>
                      </v-carousel>
                      <v-skeleton-loader
                        type="image"
                        max-width="100%"
                        class="skelton-carousel-item"
                        :height="isExtraSmall ? 250 : 165"
                        :style="`margin-top: ${
                          isExtraSmall ? '-250px' : '-165px'
                        }`"
                      ></v-skeleton-loader>
                    </v-flex>
                    <!-- Details -->
                    <v-flex class="pa-1 pb-2 flex-grow-1">
                      <v-card-text class="pb-1">
                        <v-layout row wrap>
                          <v-flex shrink class="title pa-0" v-if="trip.model">
                            {{ trip.model }}
                            <span class="caption" v-if="trip.manufacturer">
                              {{ trip.manufacturer }}
                            </span>
                            <span v-if="trip.manufacturer && trip.category" class="caption"> / </span>
                            <span class="caption" v-if="trip.category">
                              {{ trip.category }}
                            </span>
                          </v-flex>
                          <v-flex
                            class="pa-0"
                            :class="{ 'text-right': trip.model }"
                          >
                            <!-- Passengers -->
                            <v-tooltip top v-if="trip.pax">
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="ma-1 mt-0">
                                  <v-icon left color="primary">
                                    mdi-seat-passenger
                                  </v-icon>
                                  {{ trip.pax }}
                                </v-chip>
                              </template>
                              <span>Maximum no. of passenger</span>
                            </v-tooltip>
                            <!-- Availability -->
                            <v-tooltip top v-if="trip.from || trip.to">
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="ma-1 mt-0 mr-0">
                                  <v-icon left color="primary">
                                    mdi-calendar
                                  </v-icon>
                                  {{ trip.from | dateRange(trip.to) }}
                                </v-chip>
                              </template>
                              <span>Availability</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                        <!-- Airports if having destinaiton -->
                        <v-layout
                          :class="isExtraSmall ? 'column' : 'row'"
                          fill-height
                          v-if="trip.arrival"
                        >
                          <v-flex sm4 :class="{ 'text-right': !isExtraSmall }">
                            <div class="title font-weight-medium">
                              {{ trip.departure.icao }}
                              {{
                                trip.departure.iata
                                  ? "/" + trip.departure.iata
                                  : ""
                              }}
                            </div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div
                                  v-on="on"
                                  class="body-2 font-weight-regular grey--text"
                                  :class="{ 'text-truncate': !isExtraSmall }"
                                >
                                  {{ trip.departure.name }},
                                  {{
                                    trip.departure.country
                                      ? trip.departure.country.name
                                      : ""
                                  }}
                                </div>
                              </template>
                              <span>
                                {{ trip.departure.name }},
                                {{
                                  trip.departure.country
                                    ? trip.departure.country.name
                                    : ""
                                }}
                              </span>
                            </v-tooltip>
                          </v-flex>
                          <v-flex sm4>
                            <v-layout align-center>
                              <v-flex class="pa-0 pr-1 pt-3">
                                <v-slider disabled hide-details>
                                  <template slot="append">
                                    <v-icon
                                      color="grey lighten-1"
                                      style="transform: rotate(90deg)"
                                    >
                                      mdi-airplane
                                    </v-icon>
                                  </template>
                                </v-slider>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex sm4>
                            <div class="title font-weight-medium text-left">
                              {{ trip.arrival.icao }}
                              {{
                                trip.arrival.iata ? "/" + trip.arrival.iata : ""
                              }}
                            </div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div
                                  v-on="on"
                                  class="
                                    body-2
                                    font-weight-regular
                                    text-left
                                    grey--text
                                  "
                                  :class="{ 'text-truncate': !isExtraSmall }"
                                >
                                  {{ trip.arrival.name }},
                                  {{
                                    trip.arrival.country
                                      ? trip.arrival.country.name
                                      : ""
                                  }}
                                </div>
                              </template>
                              <span>
                                {{ trip.arrival.name }},
                                {{
                                  trip.arrival.country
                                    ? trip.arrival.country.name
                                    : ""
                                }}
                              </span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                        <!-- Airports Info if the destination is not yet added -->
                        <v-layout row fill-height v-else>
                          <v-flex sm12 class="py-3">
                            <v-layout row fill-height>
                              <v-flex class="text-right">
                                <v-icon
                                  :size="50"
                                  color="grey lighten-1"
                                  left
                                  class="pr-4"
                                  >mdi-airport</v-icon
                                >
                              </v-flex>
                              <v-flex>
                                <v-layout column>
                                  <div class="title font-weight-medium">
                                    {{ trip.departure.icao }}
                                    {{
                                      trip.departure.iata
                                        ? "/" + trip.departure.iata
                                        : ""
                                    }}
                                  </div>
                                  <div
                                    class="
                                      body-2
                                      font-weight-regular
                                      grey--text
                                    "
                                  >
                                    {{ trip.departure.name }},
                                    {{
                                      trip.departure.country
                                        ? trip.departure.country.name
                                        : ""
                                    }}
                                  </div>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-flex class="px-0 pt-3">
                          <v-layout row ref="featureWrap" class="featureWrap">
                            <template
                              v-for="(feature, index) in features(trip)"
                            >
                              <v-chip
                                small
                                class="mr-1 mb-1"
                                :key="`features-${index}`"
                                v-if="
                                  (index < 4 && isMediumScreen) ||
                                  !_.size(trip.images)
                                    ? true
                                    : !isMediumScreen
                                "
                              >
                                <v-icon small left color="primary">
                                  {{ feature.icon }}
                                </v-icon>
                                <span class="text-truncate">
                                  {{ feature.name }}
                                </span>
                              </v-chip>
                            </template>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  class="pointer"
                                  small
                                  v-if="
                                    _.size(features(trip)) > 4 &&
                                    isMediumScreen &&
                                    _.size(trip.images)
                                  "
                                  >+{{ _.size(features(trip)) - 4 }}</v-chip
                                >
                              </template>
                              <span>
                                {{
                                  _.join(
                                    _.map(_.slice(features(trip), 4), "name"),
                                    ", "
                                  )
                                }}
                              </span>
                            </v-tooltip>
                          </v-layout>
                        </v-flex>
                      </v-card-text>
                    </v-flex>
                    <!-- Actions -->
                    <v-flex
                      shrink
                      class="pa-5 inquire-wrap"
                      :class="
                        _.size(trip.images) &&
                        containerWidth <= $vuetify.breakpoint.thresholds.sm
                          ? 'md12'
                          : ''
                      "
                    >
                      <v-layout
                        column
                        class="text-right full-height"
                        justify-center
                      >
                        <v-flex shrink class="text-center">
                          <div class="title font-weight-bold text-center">
                            <template v-if="trip.budget">
                             {{trip.currency}} {{ trip.budget | numeral("0,0") }}
                            </template>
                            <span v-else class="caption">Not Specified</span>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  right
                                  small
                                  class="pointer"
                                  color="grey"
                                >
                                  mdi-information
                                </v-icon>
                              </template>
                              <span>
                                {{
                                  trip.budget
                                    ? "The price may vary, also the taxes might not be included."
                                    : "The pricing for the trip is not specified yet."
                                }}
                              </span>
                            </v-tooltip>
                          </div>
                          <v-btn
                            depressed
                            color="secondary"
                            :class="
                              $vuetify.breakpoint.name == 'sm' ? 'block' : ''
                            "
                            @click="toggleModal(trip)"
                          >
                            <v-icon left>mdi-help-circle-outline</v-icon>Inquire
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <!-- Inquiry Form -->
              <v-flex
                :class="isExtraSmall ? 'sm12' : 'sm8'"
                v-if="trip.toggleInquiry"
                :key="`inquiry-${index}`"
                class="mb-3 mx-auto"
              >
                <inquire
                  :trip="trip"
                  @close="trip.toggleInquiry = false"
                  :isExtraSmall="isExtraSmall"
                  :searchParams="params"
                  :company="company"
                  :config="config"
                ></inquire>
              </v-flex>
            </template>
          </template>
          <!-- No Record Found -->
          <v-flex v-if="noRecordFound">
            <v-alert
              type="info"
              color="secondary"
              border="top"
              colored-border
              dense
              class="grey lighten-5 pb-2"
              >{{ noRecordFound }}</v-alert
            >
            <v-flex :class="isExtraSmall ? 'sm12' : 'sm8'" class="mb-3 mx-auto">
              <inquire
                :isExtraSmall="isExtraSmall"
                :searchParams="params"
                :config="config"
                :company="company"
              ></inquire>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-layout row justify-center>
          <v-flex shrink>
            <v-btn
              depressed
              v-if="!loading && isLoadMore"
              color="primary"
              @click="fetchMoreData()"
            >
              Load More
            </v-btn>
            <loading v-if="loading" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import airportSearch from "@/components/airportSearch";
import inquire from "@/components/inquire";
export default {
  props: {
    company: Object,
    config: Object,
    isSmall: String,
    isExtraSmall: Boolean,
    isMediumScreen: Boolean,
    containerWidth: Number,
  },
  components: { airportSearch, inquire },
  data: () => ({
    defaultTabs: "empty-leg,one-way,round-trip",
    dialog: false,
    flexibleDaysList: false,
    trips: [],
    category: 0,
    errorMessage: null,
    loading: false,
    isLoadMore: true,
    dates: [],
    menu: false,
    params: {
      tripCategoryId: 0,
      departureAirportId: null,
      departureAirport: null,
      departureAirportRadius: 0,
      arrivalAirportId: null,
      arrivalAirport: null,
      arrivalAirportRadius: 0,
      flexibleDays: null,
      fromDate: null,
      toDate: null,
      pageNumber: 1,
      pageSize: 5,
      appCode: null,
      appWidget: null,
    },
    minDate: null,
    noRecordFound: null,
  }),
  created() {
    this.minDate = this.$moment().format("YYYY-MM-DD");
    this.params.appCode = this.config.appCode || null;
    this.params.appWidget = this.config.appWidget || null;
    this.fetchTrips();
  },
  computed: {
    tabs() {
      let defaultTabs = _.split(this.defaultTabs, ",");
      let tabs = [
        {
          id: 2544,
          name: "Empty Leg Trips",
          icon: "mdi-thumb-up-outline",
          key: "empty-leg",
        },
        {
          id: 2542,
          name: "One-way",
          icon: "mdi-ray-start-arrow",
          key: "one-way",
        },
        {
          id: 2538,
          name: "Round Trip",
          icon: "mdi-repeat",
          key: "round-trip",
        },
      ];
      let types = _.filter(
        _.split(this.config.appWidgetTypes, ","),
        _.identity
      );
      types = _.filter(types, (item) => {
        return _.includes(defaultTabs, item);
      });
      if (_.size(types)) {
        return _.map(types, (item) => {
          return _.filter(tabs, { key: item })[0];
        });
      } else {
        return tabs;
      }
    },
    showReset() {
      return (
        this.params.departureAirport != null ||
        this.params.arrivalAirport != null ||
        this.dates.length > 0
      );
    },
    transformedDates: {
      set() {},
      get() {
        return this.$options.filters.dateRange(this.dates[0], this.dates[1]);
      },
    },
    selectedTab() {
      return this.tabs[this.category];
    },
  },
  methods: {
    toggleModal(trip) {
      this.$set(trip, "toggleInquiry", true);
    },
    features(trip) {
      let features = [];
      // Air Ambulance
      trip.isAmbulance
        ? features.push({ icon: "mdi-shield-airplane", name: "Air Ambulance" })
        : null;
      // Cargo
      trip.isCargo
        ? features.push({ icon: "mdi-package-variant-closed", name: "Cargo" })
        : null;
      // Floating Fleet
      trip.isFloatingFleet
        ? features.push({
            icon: "mdi-map-marker-multiple",
            name: "Floating Fleet",
          })
        : null;
      // Medical Equipments
      trip.hasMedicalEquipments
        ? features.push({
            icon: "mdi-medical-bag",
            name: "Medical Equipments",
          })
        : null;
      // Wifi
      trip.hasWifi
        ? features.push({
            icon: "mdi-wifi",
            name: "Wifi",
          })
        : null;
      // Smoking
      trip.hasSmoking
        ? features.push({
            icon: "mdi-smoking",
            name: "Smoking",
          })
        : null;

      // hasFlightAttendant
      trip.hasFlightAttendant
        ? features.push({
            icon: "mdi-account-tie",
            name: "Flight Attendant",
          })
        : null;

      // isPetFriendly
      trip.isPetFriendly
        ? features.push({
            icon: "mdi-paw",
            name: "Pet Friendly",
          })
        : null;
      return features;
    },
    applySearch(isValidate) {
      if (isValidate && !this.$refs.cpFilters.validate()) {
        return false;
      } else if (!isValidate) {
        this.$refs.cpFilters.reset();
        this.dates = [];
      }
      this.params.pageNumber = 1;
      this.trips = [];
      this.isLoadMore = true;
      this.fetchTrips();
    },
    fetchMoreData() {
      this.params.pageNumber++;
      this.isLoadMore = true;
      this.fetchTrips();
    },
    fetchTrips() {
      this.noRecordFound = null;
      this.loading = true;
      this.params.tripCategoryId = this.tabs[this.category].id;

      this.params.departureAirportId = this.params.departureAirport
        ? this.params.departureAirport.id
        : null;
      this.params.arrivalAirportId = this.params.arrivalAirport
        ? this.params.arrivalAirport.id
        : null;
      let config = {
        headers: {
          "app-code": this.config.appCode,
          "app-widget": this.config.appWidget,
        },
      };

      let params = _.cloneDeep(this.params);
      delete params["departureAirport"];
      delete params["arrivalAirport"];

      const qs = Object.keys(_.pickBy(params, _.identity))
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      this.axios
        .get("widget/trip/search?" + qs, config)
        .then((response) => {
          if (response) {
            let paginationHeaders = JSON.parse(
              response.headers["paging-headers"]
            );

            this.isLoadMore = paginationHeaders.NextPage;
            if (_.size(response.data.result)) {
              this.trips = _.union(
                this.trips,
                _.filter(response.data.result, (item) => {
                  return item.departure != null && item.arrival != null;
                })
              );
              if (!_.size(this.trips)) {
                this.isLoadMore = false;
                this.noRecordFound =
                  "We could't find any matching results for your search criteria.";
              }
            } else if (!_.size(this.trips)) {
              this.isLoadMore = false;
              this.noRecordFound =
                "We could't find any matching results for your search criteria.";
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.statusCode == 401)
            this.$emit("error", "The specified app code is invalid.");
          this.noRecordFound =
            "Something went wrong, please try again sometime later.";
          this.isLoadMore = false;
        });
    },
  },
  watch: {
    menu: {
      immediate: false,
      handler() {
        if (this.menu == false) this.$refs.menu.save(this.dates);
      },
    },
    category() {
      this.dates = [];
      _.debounce(() => {
        this.applySearch(_.size(_.filter(this.params, _.identity)) > 5);
      }, 800)();
    },
    "params.departureAirportId"() {
      if (!this.params.departureAirportId)
        this.params.departureAirportRadius = null;
      if (_.isUndefined(this.params.departureAirportId))
        this.params.departureAirportId = null;
    },
    "params.arrivalAirportId"() {
      if (!this.params.arrivalAirportId)
        this.params.arrivalAirportRadius = null;

      if (_.isUndefined(this.params.arrivalAirportId))
        this.params.arrivalAirportId = null;
    },
    dates() {
      if (_.size(this.dates)) {
        if (_.size(this.dates) == 2) {
          if (this.$moment(this.dates[0]).isAfter(this.dates[1])) {
            this.dates = _.reverse(this.dates);
          }
        }
        this.params.fromDate = this.dates[0];
        this.params.toDate = this.dates[1] ? this.dates[1] : null;
      } else {
        this.params.fromDate = null;
        this.params.toDate = null;
        this.params.flexibleDays = null;
      }
    },
  },
};
</script>
<style lang="scss">
.cp-availability-widget {
  .trip-card {
    overflow: hidden;
  }
  .charterpad-tabs {
    .v-tab {
      text-transform: initial;
    }
  }
  .reset-btn {
    position: absolute !important;
    margin-left: 90px;
    margin-top: 5px;
  }
  .inquire-wrap {
    border: 2px solid #f5f5f5;
  }
  .cp-carousel {
    z-index: 1 !important;
    .v-carousel__controls .v-carousel__controls__item {
      margin: 0px !important;
      .v-icon {
        font-size: 8px !important;
      }
    }
  }
  .skelton-carousel-item {
    position: relative !important;
    z-index: 0;
    .v-skeleton-loader__image {
      height: 100% !important;
    }
  }
  .v-date-picker-table table {
    border: none !important;
    tr td {
      padding: 0px !important;
      border: none !important;
    }
  }
}
</style>
